export function getCurrencySign(currency: string | null) {
  switch (currency && currency.toLowerCase()) {
    case "eur":
      return "€";
    case "gbp":
      return "£";
    case "usd":
    default:
      return "$";
  }
}

export function formatCurrency(price: number, curr: string | null = null, decimal = 0) {
  const currency = getCurrencySign(curr);
  const fixedPrice = Number(price || 0).toFixed(decimal);

  return `${currency}${fixedPrice}`;
}
